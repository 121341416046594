import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '!svg-react-loader!../../images/vectors/close_icon.svg';
import {
  apiSubmitSmartRepBookDemo,
  apiValidateSRPhone,
} from '../../services/api-service';
import { councilOptions, LocalStorageKeys } from '../../utils/constants';
import { getGlobalVar, setGlobalVar } from '../../utils';
import { ModalButton } from '../extra-components';


function handleErrors(response) {
  if (!response.ok) {
    throw Error('Error');
  }
  return response;
}

const PhoneNumberItem = ({ updateValidationStatus, demoDetails }) => {
  const phoneNumberRef = useRef(null);
  const formItemRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const REASON_DIGITS_NOT_ENOUGH = `Enter 10 digits`;
  const [reason, setReason] = useState('');

  const timeout = (ts) => new Promise(resolve => {
    setTimeout(resolve, ts);
  });

  const submitPhoneNumber = async ({ phoneNumber }) => {
    // await timeout(1000);
    // return { phoneNumber, status: true, reason: 'valid' };

    const response = await apiValidateSRPhone({ phoneNumber });
    console.log('response::', response);
    return response;
  };

  const updatePhoneNumberValidity = ({ valid, status, reason }) => {
    setLoading(false);
    if (valid) {
      phoneNumberRef.current.setCustomValidity('');
      formItemRef.current.classList.add('was-validated');
    } else {
      if (reason === REASON_DIGITS_NOT_ENOUGH) {
        formItemRef.current.classList.remove('was-validated');
        phoneNumberRef.current.setCustomValidity(reason);
      } else {
        formItemRef.current.classList.add('was-validated');
        phoneNumberRef.current.setCustomValidity(reason);
        // what if it's demo already booked. we should tell the doctor what his next steps can be.
      }
      setReason(reason);
    }
    updateValidationStatus(valid);
  };

  useEffect(() => {
    if (phoneNumber.length === 10) {
      setLoading(true);
      submitPhoneNumber({ phoneNumber })
        .then(updatePhoneNumberValidity)
        .catch(() => {
          setLoading(false);
        });
    } else {
      updatePhoneNumberValidity({ valid: false, reason: REASON_DIGITS_NOT_ENOUGH });
    }
  }, [phoneNumber]);


  useEffect(() => {
    console.log('phone number effect started', phoneNumberRef.current);
    if (phoneNumberRef.current) {
      phoneNumberRef.current.focus();
    }
  }, []);


  const onInputChanged = e => {
    if (e.target.id === 'phoneNumber') {
      const updatedValue = e.target.value;
      if (updatedValue.length <= 10) {
        setPhoneNumber(updatedValue);
      }
    }
  };
  return (
    <>
      <p>Please share your contact number and allow us to reach out to you on WhatsApp.</p>

      <div className="mt-form-item" ref={formItemRef}>
        <label htmlFor="phoneNumber">Phone Number</label>
        <div className="input-group">
          <span className="input-group-text" id="inputGroupPrepend">+91</span>
          <input
            ref={phoneNumberRef}
            pattern="\d*"
            type="number" className="form-control" id="phoneNumber" placeholder="Phone Number"
            aria-describedby="inputGroupPrepend" required
            value={phoneNumber} onChange={onInputChanged}
            disabled={loading}
          />
          <div className="invalid-feedback" style={{ color: '#dc3545' }}>
            {reason}
          </div>
        </div>
        {loading ? <div className="spinner-border mt-form-valid-status" role="status" /> : null}

      </div>
    </>
  );
};

const EmailInputItem = ({ }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <motion.div
    // initial={{ opacity: 0, y: 100 }}
    // animate={{ opacity: 1, y: 0 }}
    >
      <div className="mt-form-item">
        <label htmlFor="email">Your Email</label>
        <div className="input-group">
          <input
            ref={inputRef}
            type="text" className="form-control" id="email" placeholder="name@company.com"
            aria-describedby="inputGroupPrepend" required
          />
        </div>
      </div>
    </motion.div>
  );
};

const DesignationInputItem = ({ }) => {
  const inputRef = useRef(null);
  return (
    <motion.div
    // initial={{ opacity: 0, y: 100 }}
    // animate={{ opacity: 1, y: 0 }}
    >
      <div className="mt-form-item">
        <label htmlFor="designation">Designation</label>
        <div className="input-group">
          <input
            ref={inputRef}
            type="text" className="form-control" id="designation" placeholder="Enter Designation"
            required
          />
        </div>
      </div>
    </motion.div>
  );
};

const NameInputItem = ({ }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <motion.div
    // initial={{ opacity: 0, y: 100 }}
    // animate={{ opacity: 1, y: 0 }}
    >
      <div className="mt-form-item">
        <label htmlFor="name">Name</label>
        <div className="input-group">
          <input
            ref={inputRef}
            type="text" className="form-control" id="name" placeholder="Your Name"
            required
          />
        </div>
      </div>
    </motion.div>
  );
};

const CompanyNameInputItem = ({ }) => {
  const inputRef = useRef(null);
  return (
    <motion.div
      // initial={{ opacity: 0, y: 100 }}
      // animate={{ opacity: 1, y: 0 }}
    >
      <div className="mt-form-item">
        <label htmlFor="company">Company</label>
        <div className="input-group">
          <input
            ref={inputRef}
            type="text" className="form-control" id="company" placeholder="Your Company Name"
            required
          />
        </div>
      </div>
    </motion.div>
  );
};


const BookDemoPage = ({ bookDemoClicked, location, dynamic, cType, pType }) => {

  const DEMO_HASH = '#demo';
  const isDefaultDemoOpened = location && location.hash && location.hash.indexOf(DEMO_HASH) > -1 || false;
  // const demoBookedDefault = getGlobalVar(LocalStorageKeys.MT_DEMO_BOOKED) === 'true';
  const demoBookedDefault = false;
  const demoDetailsRaw = getGlobalVar(LocalStorageKeys.MT_DEMO_DETAILS);
  const demoDetails = demoDetailsRaw ? JSON.parse(demoDetailsRaw) : {};

  const form = useRef(null);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [regNoExists, setRegNoExists] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [demoBooked, setDemoBooked] = useState(demoBookedDefault);
  const cRef = useRef({});


  const validateForSubmit = (params) => {
    const { phoneNumber, name, designation, company } = params;
    if (!phoneNumber || phoneNumber.length !== 10) {
      return false;
    }
    if (!name || name.trim().length === 0) {
      return false;
    }
    if (!designation || designation.trim().length === 0) {
      return false;
    }
    if (!company || company.trim().length === 0) {
      return false;
    }
    return true;
  };

  const onSubmitClicked = async (e) => {
    e.preventDefault();
    const formData = {};
    for (let i = 0; form.current[i]; i++) {
      const item = form.current[i];
      if (item.id) {
        if (item.type === 'checkbox') {
          formData[item.id] = item.checked;
        } else {
          formData[item.id] = item.value;
        }
      }
    }
    if (validateForSubmit(formData)) {
      console.log('form data is valid to submit', formData);
      try {
        setSubmitLoading(true);
        formData.cType = cType;
        formData.pType = pType;
        const apiResponse = await apiSubmitSmartRepBookDemo(formData);
        console.log('api response: ', apiResponse);
        setSubmitLoading(false);
        setDemoBooked(true);
        setGlobalVar(LocalStorageKeys.MT_DEMO_BOOKED, 'true');
        setGlobalVar(LocalStorageKeys.MT_DEMO_DETAILS, JSON.stringify(formData));
      } catch (e) {
        setSubmitLoading(false);
        console.log('error', e);
      }
    } else {
      console.log('invalid: formData: ', formData);
    }
  };

  const updatePhoneNumberValidStatus = (status) => {
    setPhoneNumberValid(status);
  };


  const modalContent = (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <form onSubmit={onSubmitClicked} ref={form} className="book-demo-form needs-validation" noValidate>
        <div className="form-row">
          <PhoneNumberItem updateValidationStatus={updatePhoneNumberValidStatus} demoDetails={demoDetails} />
        </div>
        <div className="form-row">
          <AnimatePresence>
            {phoneNumberValid ? <NameInputItem key="name-input" demoDetails={demoDetails} /> : null}
            {phoneNumberValid ? <CompanyNameInputItem key="company-input" demoDetails={demoDetails} /> : null}
            {phoneNumberValid ? <DesignationInputItem key="designation-input" demoDetails={demoDetails} /> : null}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          <motion.div>
            <div className="mt-form-item">
              <button type="submit" className="btn btn-primary">
                {submitLoading ? (
                  <>
                    Submitting <div className="spinner-border" role="status" />
                  </>
                ) : 'Submit'}
              </button>
            </div>
          </motion.div>
        </AnimatePresence>


      </form>
    </motion.div>
  );

  const modalSuccessContent = (
    <div className='container book-demo-form'>
      <div className='book-demo-success'>
        <h4>Thank you for your interest!</h4>
        <p>Our team will reach out to you within next 48 hours to verify details and organise the demo as per your
          convenience. We look forward to serving you. </p>
        <button className="btn btn-default" onClick={() => {
          console.log(cRef.current.closeModal());
        }}>
          Okay
        </button>
      </div>
    </div>
  );

  const demoButton = (
    <motion.div
      // className={`mt-btn book-demo-footer-fixed ${demoBooked ? 'mt-remove-display' : null}`}
      className={`mt-btn book-demo-footer-fixed container ${dynamic ? 'book-demo-dynamic' : ''}`}
      initial='hidden'
      animate='visible'
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: dynamic ? 0 : 1 } }
      }}
    >
      <span className={`page-footer-text mt-track-click`}>Book Demo</span>
    </motion.div>
  );


  return (
    <>
      <ModalButton
        modalTitle='Book Demo'
        button={demoButton}
        cRef={cRef}
      >
        {demoBooked ? modalSuccessContent : modalContent}
      </ModalButton>
    </>
  );
};


export default BookDemoPage;
